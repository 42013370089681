@import "../../scss/index.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #101010;
}

img.img-responsive {
  height: 70%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

ul.slick-dots {
  position: static;
  top: 100%;
  font-size: 70%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  list-style: none;
  text-align: center;
  background-color: #101010;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button.slick-prev:before {
  top: -200%;
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  margin-left: 250%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -80px;
}


ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: -1px;
  text-decoration: none;
  width: 100%;
}

a.ui.button.btn-download-dos {
  position: relative;
  margin-top: -50px;
  width: 50%;
  max-width: 200px;
  // margin-left: 35%;
  // margin-right: 60%;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 90%;
  border-radius: 50px;

  &:hover {
    background-color: $primary-color-cyan-hover;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
 // border: 3px solid green; 
}

h2.lesson2title {
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

